import React from 'react'

import { Helmet } from 'react-helmet'

import Navigation from '../../components/navigation'
import Tag from '../../components/tag'
import Footer from '../../components/footer'
import './blog-partnership-with-wirex.css'

const BlogPartnershipWithWirex = (props) => {
  return (
    <div className="blog-partnership-with-wirex-container1">
      <Helmet>
        <title>Gateway.fm Partnership with Wirex</title>
        <meta
          name="description"
          content="Learn about the strategic partnership between Gateway.fm and Wirex, combining expertise in blockchain and financial technology to drive innovation and user-centric solutions."
        />
        <meta property="og:title" content="Gateway.fm Partnership with Wirex" />
        <meta
          property="og:description"
          content="Learn about the strategic partnership between Gateway.fm and Wirex, combining expertise in blockchain and financial technology to drive innovation and user-centric solutions."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/9d7b9f9e-096a-4181-bbdb-9b56e1288709?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name34"></Navigation>
      <div className="blog-partnership-with-wirex-container2">
        <div className="blog-partnership-with-wirex-post">
          <img
            alt="Gateway.fm and Wirex partnership."
            src="/external/blog-banners/gateway-wirex-partnership-announcement-1200w.png"
            className="blog-partnership-with-wirex-image1"
          />
          <div className="blog-partnership-with-wirex-text10">
            <div className="blog-partnership-with-wirex-container3">
              <div className="blog-partnership-with-wirex-tags">
                <Tag text="crypto finance"></Tag>
                <Tag text="polygon cdk"></Tag>
              </div>
              <span className="blog-partnership-with-wirex-text11">
                Gateway Partnership with Wirex
              </span>
              <span className="blog-partnership-with-wirex-text12">
                5th of February 2024
              </span>
              <span className="blog-partnership-with-wirex-text13">
                <span>
                  We are thrilled to announce our collaboration with Wirex!
                  Together, we have strengthened Wirex’s services by seamlessly
                  integrating L2 via our cutting-edge PaaS Presto, powered by
                  Polygon CDK. This initiative aligns Wirex&apos;s technology
                  with specific compliance and operational requirements.
                </span>
                <br></br>
                <br></br>
                <span>
                  The Gateway-Wirex partnership advances blockchain-driven
                  financial services. They utilize Gateway&apos;s Presto,
                  powered by Polygon CDK, to integrate Layer 2 solutions for
                  enhanced compliance and operations at Wirex. Smart contracts
                  boost security and efficiency, enabling decentralized Wirex
                  Card operations. This enhances WPay&apos;s self-custody, asset
                  flexibility, and instant spending. Gateway&apos;s use of
                  private zkEVM rollups improves scalability and cost
                  efficiency, reducing Ethereum mainnet strain. This partnership
                  signifies a strategic advancement in blockchain payment
                  solutions for an efficient and adaptable crypto payment
                  ecosystem.
                </span>
                <br></br>
                <br></br>
                <span>
                  From Gateway, we have provided private zkEVM rollups for Wirex
                  payment solution, significantly improving scalability, cost
                  efficiency and enhancing the network&apos;s transaction
                  throughput while reducing the strain on the Ethereum mainnet.
                  Our instant transaction processing secured with zkProofs, that
                  are sent on L1 ensures uninterrupted services while enabling
                  adaptable features. Together, we have facilitated a new
                  product in addition to the existing Wirex payment portfolio,
                  without disrupting the existing ecosystem. Leveraging Polygon
                  CDK in off-chain transaction processing not only enhances
                  security but also achieves gasless transactions. This coupled
                  with a strategic offloading of transactions from the Ethereum
                  mainnet, alleviates congestion and enhances overall network
                  efficiency.
                </span>
                <br></br>
                <br></br>
                <span>
                  In a monumental leap forward, Gateway and Wirex proudly
                  announce the beginning of a partnership and ambitious project
                  to integrate a cutting-edge blockchain-based system into its
                  card services. Drawing inspiration from the proven success of
                  the PaaS powered by Presto model, this innovative move is
                  poised to redefine Wirex&apos;s standing in the
                  crypto-financial industry.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span>Project Overview</span>
                <br></br>
                <br></br>
                <span>
                  The primary objective of this project was to achieve the
                  seamless integration of blockchain technology into
                  Wirex&apos;s existing card processing and banking
                  infrastructure, which entails a comprehensive endeavor
                  bridging web3 and traditional finance, necessitating the
                  customization of the Presto model and the implementation of a
                  sophisticated smart contract platform.
                </span>
                <br></br>
                <br></br>
                <span>
                  WPay represents a pioneering leap in the world of digital
                  payments, serving as the vanguard of innovation by bridging
                  the gap between conventional finance and the burgeoning realm
                  of decentralized finance (DeFi). Developed as an integral
                  component of Wirex&apos;s extensive suite of financial
                  services, WPay harnesses the potential of blockchain
                  technology to offer users a seamless, secure, and user-centric
                  experience for managing and transacting with digital assets.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span>What is Paas Presto?</span>
                <br></br>
                <br></br>
                <span>
                  Presto stands as a platform that manages deployment of zkEVM
                  rollups, offering enhanced scalability for blockchain networks
                  without compromising on security or trustlessness.
                  Zero-knowledge rollups, at the core of Presto&apos;s design,
                  provide transaction processing by bundling multiple
                  transactions together and submitting a compressed version of
                  the data to the main blockchain. This significantly reduces
                  computational workload and alleviates blockchain congestion,
                  ensuring optimal network performance. For further details, see
                  our documentation on Presto.
                </span>
                <br></br>
                <br></br>
                <span>
                  Presto also provides innovative privacy-preserving zkEVM
                  rollup solutions, setting new standards for scalability,
                  efficiency, and privacy in blockchain networks. Gateway
                  remains at the forefront of innovation, particularly in
                  exploring the utility of Polygon&apos;s Cloud Development Kit
                  (CDK). Gateway&apos;s exploration of Polygon CDK signifies a
                  commitment to technical innovation.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span>Key Components</span>
                <br></br>
              </span>
              <img
                alt="image"
                src="/external/blog-img/gatway-architecture-wirexpax-solution-800w.jpeg"
                className="blog-partnership-with-wirex-image2"
              />
              <span className="blog-partnership-with-wirex-text49">
                <br></br>
                <span className="blog-partnership-with-wirex-text51">
                  Smart Contracts:
                </span>
                <span>
                  {' '}
                  WPay leverages a sophisticated system of smart contracts
                  designed to facilitate the integration of the Web3 ecosystem
                  with traditional payment processing. This includes advanced
                  mechanisms for increased transaction limits, ensuring
                  scalability to accommodate a growing user base.
                </span>
                <br></br>
                <br></br>
                <span>
                  KYC Integration: The blockchain system has been seamlessly
                  integrated with Wirex&apos;s existing off-chain KYC
                  procedures, ensuring regulatory compliance, especially in the
                  light of EU’s MICA regulations. Backend Oracle services
                  provide communication between Wirex off-chain KYC and on-chain
                  smart contracts. By mirroring the on-chain KYC data, ensuring
                  the security and efficiency for L1 verification.
                </span>
                <br></br>
                <br></br>
                <span>
                  Processing System Integration: The new blockchain system is
                  now seamless, almost instant and integrated with Wirex&apos;s
                  current processing system for Visa and Mastercard
                  transactions. This integration guarantees instant transaction
                  confirmations and operational continuity and efficiency during
                  transactions.
                </span>
                <br></br>
                <br></br>
                <span>
                  Transaction Bridging Existing zkEVM bridges have been utilized
                  for transferring funds from Layer 1 to the card. This ensures
                  compatibility and efficiency in the transfer process directly
                  on the blockchain. Clients are now able to bypass centralized
                  exchanges and interact with the blockchain directly.
                </span>
                <br></br>
                <br></br>
                <span>
                  Private Rollup: A Layer 2 scaling solution that enhances
                  transaction privacy and reduces the load on the main Ethereum
                  network. Ensuring faster transaction processing and no fees
                  while maintaining the security guarantees of the Ethereum
                  mainnet. Gateway&apos;s approach reduces the strain on the
                  Ethereum mainnet, ensuring that Wirex&apos;s payment services
                  remain robust and reliable even during periods of high demand.
                  L2 transaction processing, secured with zkProofs, plays a
                  pivotal role in alleviating congestion on the Ethereum
                  mainnet. This is done by sending the transactions directly to
                  the L2 and storing them on the DAC ( Decentralized Autonomous
                  Committee). Presto bridges facilitate the transfer of assets
                  between Layer 1 (L1) and Layer 2 (L2) blockchains.
                </span>
                <br></br>
                <br></br>
                <span>
                  Users initiate the transfer process by bridging funds from L1
                  to L2. Once the funds are on L2, Visa or Mastercard initiates
                  the transaction by calling Wirex&apos;s off-chain system.
                  Wirex’s off-chain system, in turn, communicates with the
                  processing oracle, which acts as a bridge between the
                  blockchain and Wirex’s existing card processing system that is
                  integrated with Visa and Mastercard. The processing oracle
                  then calls the smart contract on L2 to execute the
                  transaction. This is instantly computed on L2 and verification
                  of the changes are generated and verified on L1.
                </span>
                <br></br>
                <br></br>
                <span>
                  L1 &amp; L2 RPC: We are also a proven RPC (Remote Procedure
                  Call) provider with a robust track record. RPC providers act
                  as intermediaries between the dApp and the blockchain,
                  abstracting the complexities of network communication and
                  providing developers with a convenient way to interact with
                  the blockchain. Like a software library or service that
                  facilitates communication between a dApp and a blockchain
                  network, allowing developers to interact with the blockchain
                  using programmable APIs.
                </span>
                <br></br>
                <br></br>
                <span>
                  Scalability and Cost Efficiency:This innovative solution
                  enhances the network&apos;s transaction throughput, providing
                  users with a seamless and efficient payment experience,
                  without disrupting the existing ecosystem. The implementation
                  enables the seamless introduction of new features and
                  optimizations, demonstrating a technical edge over solutions
                  that may encounter challenges in maintaining upgradable
                  contracts.
                </span>
                <br></br>
                <br></br>
                <span>
                  Presto provides innovative privacy-preserving zkEVM rollup
                  solutions, setting new standards for scalability, efficiency,
                  and privacy in blockchain networks. Gateway remains at the
                  forefront of innovation, particularly in exploring the utility
                  of Polygon&apos;s Cloud Development Kit (CDK). Gateway&apos;s
                  exploration of Polygon CDK signifies a commitment to technical
                  innovation.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span>Conclusion</span>
                <br></br>
                <br></br>
                <span>
                  The collaboration between Gateway and Wirex, powered by
                  Presto&apos;s private zkEVM rollup, marks a significant
                  milestone in the realm of blockchain scalability and payment
                  solutions. The implementation not only solves immediate
                  challenges but sets the stage for a future-proof, efficient,
                  upgradable, and adaptable crypto payment ecosystem.
                </span>
                <br></br>
                <br></br>
                <span>
                  In the technical landscape, zkEVM by Gateway PaaS Presto
                  emerges not just as a scaling solution but as a manifestation
                  of meticulous cryptographic engineering and innovative
                  architecture. With a focus on privacy, scalability,
                  efficiency, and adaptability, zkEVM redefines the technical
                  benchmarks for blockchain scalability, providing developers
                  and enterprises with an advanced toolkit for the next
                  generation of decentralized applications.
                </span>
                <br></br>
                <br></br>
                <span>
                  We are happy to announce this partnership and further develop
                  the crypto-financial industry.
                </span>
                <br></br>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer rootClassName="footerroot-class-name33"></Footer>
    </div>
  )
}

export default BlogPartnershipWithWirex
