import React from 'react'

import { Helmet } from 'react-helmet'

import Navigation from '../../components/navigation'
import Tag from '../../components/tag'
import Footer from '../../components/footer'
import './blog-gatewayfm-wirex-case-study.css'

const BlogGatewayfmWirexCaseStudy = (props) => {
  return (
    <div className="blog-gatewayfm-wirex-case-study-container1">
      <Helmet>
        <title>Gateway.fm & Wirex Case Study</title>
        <meta
          name="description"
          content="Explore the collaboration case study between Gateway.fm and Wirex, showcasing innovative solutions at the intersection of blockchain and financial technology."
        />
        <meta property="og:title" content="Gateway.fm &amp; Wirex Case Study" />
        <meta
          property="og:description"
          content="Explore the collaboration case study between Gateway.fm and Wirex, showcasing innovative solutions at the intersection of blockchain and financial technology."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/eb76177e-6b4f-4acb-a563-96caf824f4ca?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name13"></Navigation>
      <div className="blog-gatewayfm-wirex-case-study-container2">
        <div className="blog-gatewayfm-wirex-case-study-post">
          <img
            alt="Wirex and Gateway.fm."
            src="/external/blog-banners/gateway-wirex-case-study-1200w.png"
            className="blog-gatewayfm-wirex-case-study-image1"
          />
          <div className="blog-gatewayfm-wirex-case-study-text100">
            <div className="blog-gatewayfm-wirex-case-study-container3">
              <div className="blog-gatewayfm-wirex-case-study-tags">
                <Tag text="crypto finance"></Tag>
                <Tag text="web3"></Tag>
              </div>
              <span className="blog-gatewayfm-wirex-case-study-text101">
                A Partnership That Transformed The Crypto-Financial Industry
              </span>
              <span className="blog-gatewayfm-wirex-case-study-text102">
                7of June 2024
              </span>
              <span className="blog-gatewayfm-wirex-case-study-text103">
                <span>
                  The partnership between Gateway.fm and Wirex ushered in a new
                  era of blockchain-driven financial services, marking a
                  significant milestone in crypto payments. Through the
                  integration of Wirex&apos;s services with our Presto service,
                  powered by Polygon CDK, we achieved results that set new
                  standards in compliance and operations in the crypto-financial
                  industry.
                </span>
                <br></br>
                <br></br>
                <span>
                  Key components of our collaboration include the integration of
                  L2 solutions, smart contracts, KYC procedures, private zkEVM
                  rollups, and transaction bridging. We provided solutions for
                  security, scalability and cost efficiency. This partnership
                  has not only optimized transaction throughput but also reduced
                  strain on the Ethereum mainnet, ensuring reliable payment
                  services for Wirex users.
                </span>
                <br></br>
                <br></br>
                <span>
                  Presto manages zkEVM rollup deployment, enhancing blockchain
                  scalability while maintaining security and trustlessness -
                  reducing computational workload and alleviating blockchain
                  congestion. Presto&apos;s privacy-preserving zkEVM rollup
                  solutions enable unprecedented levels of scalability,
                  efficiency, and privacy in blockchain networks.
                </span>
                <br></br>
                <br></br>
                <span>
                  Furthermore, our implementation of Polygon&apos;s CDK gives
                  expression to our commitment to technical innovation. By
                  leveraging CDK, we achieved gasless transactions and enhanced
                  security in off-chain transaction processing, further
                  improving the efficiency of Wirex&apos;s payment ecosystem.
                </span>
                <br></br>
                <br></br>
                <span>
                  In summary, the collaboration between Gateway.fm and Wirex
                  represents a significant advancement in blockchain scalability
                  and payment solutions. The project&apos;s primary objective
                  was to seamlessly integrate blockchain technology into
                  Wirex&apos;s existing card processing and banking
                  infrastructure.
                </span>
                <br></br>
                <br></br>
                <span>
                  Through the customization of our Presto implementation and a
                  sophisticated smart contract platform, we achieved this
                  objective while bridging the gap between conventional finance
                  and DeFi. Our joint efforts have not only addressed immediate
                  challenges but have also laid the foundation for a
                  future-proof, efficient and adaptable crypto payment
                  ecosystem.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span>The Technical Implementation</span>
                <br></br>
                <br></br>
                <span>
                  The technical architecture of our integration with Wirex
                  encompasses several key components that highlight the depth
                  and breadth of our collaboration.
                </span>
                <br></br>
                <br></br>
              </span>
              <img
                alt="image"
                src="/external/blog-img/gateway-wirex-pay-architecture-flow-chart-800w.webp"
                className="blog-gatewayfm-wirex-case-study-image2"
              />
              <span className="blog-gatewayfm-wirex-case-study-text129">
                <span>Smart Contracts</span>
                <br></br>
                <br></br>
                <a
                  href="https://wirexapp.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Wirex Pay
                </a>
                <span>
                  {' '}
                  leverages a sophisticated system of smart contracts designed
                  to facilitate the integration of the Web3 ecosystem with
                  traditional payment processing. This includes advanced
                  mechanisms for increased transaction limits, ensuring
                  scalability to accommodate a growing user base.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span>KYC Integration</span>
                <br></br>
                <br></br>
                <span>
                  The blockchain system has been seamlessly integrated with
                  Wirex&apos;s existing off-chain KYC procedures, ensuring
                  regulatory compliance, especially in light of the EU&apos;s
                  MICA regulations. Backend Oracle services provide
                  communication between Wirex&apos;s off-chain KYC and on-chain
                  smart contracts, mirroring the on-chain KYC data to ensure
                  security and efficiency for L1 verification.
                </span>
                <br></br>
                <br></br>
                <span>Processing System Integration</span>
                <br></br>
                <span>
                  The new blockchain system is now seamlessly integrated with
                  Wirex&apos;s current processing system for Visa and Mastercard
                  transactions. This integration guarantees instant transaction
                  confirmations and operational continuity and efficiency during
                  transactions.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span>Transaction Bridging</span>
                <br></br>
                <br></br>
                <span>
                  Existing zkEVM bridges have been utilized for transferring
                  funds from Layer 1 to the card, ensuring compatibility and
                  efficiency in the transfer process directly on the blockchain.
                  Clients can now bypass centralized exchanges and interact with
                  the blockchain directly.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span>Private Rollup</span>
                <br></br>
                <br></br>
                <span>
                  A Layer 2 scaling solution enhances transaction privacy and
                  reduces the load on the main Ethereum network, ensuring faster
                  transaction processing and no fees while maintaining the
                  security guarantees of the Ethereum mainnet. Gateway&apos;s
                  approach reduces the strain on the Ethereum mainnet, ensuring
                  that Wirex&apos;s payment services remain robust and reliable
                  even during periods of high demand. L2 transaction processing,
                  secured with zkProofs, plays a pivotal role in alleviating
                  congestion on the Ethereum mainnet by sending transactions
                  directly to L2 and storing them on the DAC (Decentralized
                  Autonomous Committee). Presto bridges facilitate the transfer
                  of assets between Layer 1 (L1) and Layer 2 (L2) blockchains.
                </span>
                <br></br>
                <br></br>
                <span>
                  Users initiate the transfer process by bridging funds from L1
                  to L2. Once the funds are on L2, Visa or Mastercard initiates
                  the transaction by calling Wirex&apos;s off-chain system.
                  Wirex&apos;s off-chain system, in turn, communicates with the
                  processing oracle, which acts as a bridge between the
                  blockchain and Wirex&apos;s existing card processing system
                  integrated with Visa and Mastercard. The processing oracle
                  then calls the smart contract on L2 to execute the
                  transaction. This is instantly computed on L2 and verification
                  of the changes is generated and verified on L1.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span>RPC on L1 &amp; L2</span>
                <br></br>
                <br></br>
                <span>
                  We are also a proven RPC (Remote Procedure Call) provider with
                  a robust track record. RPC providers act as intermediaries
                  between the dApp and the blockchain, abstracting the
                  complexities of network communication and providing developers
                  with a convenient way to interact with the blockchain, much
                  like a software library or service that facilitates
                  communication between a dApp and a blockchain network,
                  allowing developers to interact with the blockchain using
                  programmable APIs.
                </span>
                <br></br>
                <br></br>
                <span>Cost Efficiency and Scalability</span>
                <br></br>
                <span>
                  This innovative solution enhances the network&apos;s
                  transaction throughput, providing users with a seamless and
                  efficient payment experience without disrupting the existing
                  ecosystem. The implementation enables the seamless
                  introduction of new features and optimizations, demonstrating
                  a technical edge over solutions that may encounter challenges
                  in maintaining upgradable contracts. Gateway remains at the
                  forefront of innovation, particularly in exploring the utility
                  of Polygon&apos;s Cloud Development Kit (CDK).
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span>Implementation of Node Sales</span>
                <br></br>
                <br></br>
                <span>
                  We provided
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <a
                  href="https://presale.wirexpaychain.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Wirex Pay Node Sale
                </a>
                <span>
                  {' '}
                  with a comprehensive Node as a Service (NaaS) solution through
                  our NodeHub platform. This service simplifies the technical
                  complexities of node distribution and operation, ensuring a
                  seamless and user-friendly experience for node ownership. Key
                  features of NodeHub include a structured distribution process
                  with tiered options, a dedicated web interface for easy
                  navigation, secure purchases and the use of NFTs to represent
                  node licenses. These NFTs provide verifiable proof of
                  ownership and transferability on secondary markets. This
                  partnership empowers the Wirex Pay community to actively
                  participate in network security and governance.
                </span>
                <br></br>
                <br></br>
                <span>
                  The Node Sales implementation deepenes our partnership with
                  Wirex while strengthening the decentralization of Wirex
                  Pay&apos;s infrastructure for a seamless and user-friendly
                  node ownership experience.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span>Reflections on the Partnership</span>
                <br></br>
                <br></br>
                <span>
                  &quot;We are thrilled to partner with Gateway.fm to enhance
                  the scalability and efficiency of our Wirex Pay platform. It
                  underscores our dedication to delivering cutting-edge,
                  user-centric solutions. With Presto&apos;s capabilities, we
                  are confident that Wirex Pay will continue to redefine the way
                  people use digital assets in their everyday lives.&quot;
                </span>
                <br></br>
                <span>- Pavel Matveev, CEO &amp; Co-founder, Wirex</span>
                <br></br>
                <br></br>
                <br></br>
                <span>
                  &quot;This partnership gives expression to the power of
                  private zkEVM roll-ups in terms of driving security,
                  scalability, and cost efficiencies for important real-world
                  use cases. By leveraging our Platform-as-a-Service Presto,
                  Wirex&apos;s growing user base can now access streamlined
                  blockchain-enhanced financial services, and we look forward to
                  broadening the scope of this partnership in the months
                  ahead.&quot;
                </span>
                <br></br>
                <span>- Igor Mandrigin, Co-Founder and CTO, Gateway.fm</span>
                <br></br>
                <br></br>
                <br></br>
                <span>
                  &quot;We&apos;re thrilled to partner with Wirex and expand the
                  benefits of our Presto solution to over six million users
                  across the world. The synergies between Gateway.fm and Wirex
                  are clear - much like how we&apos;re abstracting the
                  complexity of blockchain infrastructure, Wirex is simplifying
                  the process of buying, selling, and spending digital assets.
                  The compound effect of these missions will be huge for
                  facilitating mainstream Web3 adoption.&quot;
                </span>
                <br></br>
                <span>- Cuautemoc Weber, Co-Founder and CEO, Gateway.fm</span>
                <br></br>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer rootClassName="footerroot-class-name12"></Footer>
    </div>
  )
}

export default BlogGatewayfmWirexCaseStudy
