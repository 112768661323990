import React from 'react'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Navigation from '../../components/navigation'
import Tag from '../../components/tag'
import Footer from '../../components/footer'
import './from-rpc-to-real-world.css'

const FromRPCToRealWorld = (props) => {
  return (
    <div className="from-rpc-to-real-world-container1">
      <Helmet>
        <title>From RPC to Real World</title>
        <meta
          name="description"
          content="Igor Mandrigin, CTPO and co-founder of Gateway.fm discusses how blockchain infrastructure is evolving and why Gateway.fm is at the forefront of this change."
        />
        <meta property="og:title" content="From RPC to Real World" />
        <meta
          property="og:description"
          content="Igor Mandrigin, CTPO and co-founder of Gateway.fm discusses how blockchain infrastructure is evolving and why Gateway.fm is at the forefront of this change."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/3bebedca-eb60-4947-a4aa-d3c45af2e8cf?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name63"></Navigation>
      <div className="from-rpc-to-real-world-container2">
        <div className="from-rpc-to-real-world-post">
          <img
            alt="Web3 DeFi Staking podcast."
            src="/external/blog-banners/igor-mandrigin-gateway-web3-1200w.jpg"
            className="from-rpc-to-real-world-image"
          />
          <div className="from-rpc-to-real-world-text10">
            <div className="from-rpc-to-real-world-container3">
              <div className="from-rpc-to-real-world-tags">
                <Tag text="podcast"></Tag>
                <Tag text="web3"></Tag>
              </div>
              <span className="from-rpc-to-real-world-text11">
                From RPC to Real World
              </span>
              <span className="from-rpc-to-real-world-text12">
                19 of November 2024
              </span>
              <span className="from-rpc-to-real-world-text13">
                <span>
                  Blockchain technology is no longer the wild frontier it once
                  was. Its potential is being realized far beyond
                  cryptocurrencies, finding its way into industries like supply
                  chains, finance and even governmental programs. We sat down
                  with Igor Mandrigin, CTPO and co-founder, to discuss how
                  blockchain infrastructure is evolving and why Gateway.fm is at
                  the forefront of this change.
                </span>
                <br className="from-rpc-to-real-world-text15"></br>
              </span>
              <div className="from-rpc-to-real-world-container4">
                <div className="from-rpc-to-real-world-container5">
                  <Script
                    html={`<div
  style="
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
  "
>
  <iframe
    src="https://www.youtube.com/embed/Ju-YxoqNHqM?si=BAqAYIj_gYm7_uSM"
    style="
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    "
  ></iframe>
</div>
`}
                  ></Script>
                </div>
              </div>
              <span className="from-rpc-to-real-world-text16">
                <span className="h2">
                  The Inflection Point of Blockchain Technology
                </span>
                <br></br>
                <br></br>
                <span>
                  “Whether we like it or not, the speculative hype around
                  cryptocurrencies has largely cooled,” Igor begins. “But what’s
                  come to light now is even more interesting—the underlying
                  technology. Blockchain’s transactional mechanisms are being
                  applied across sectors, and it’s maturing steadily.”
                </span>
                <br></br>
                <br></br>
                <span>
                  Igor explains that we’ve reached a tipping point. “The
                  technology is finally scalable. It can handle hundreds of
                  millions of transactions daily, offers bank-grade security,
                  and is much faster and more efficient than before. We’re
                  talking 240 times faster sync times and a fraction of the disk
                  space requirements. This is what mass adoption looks like.”
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span className="h2">A Problem Born Out of Centralization</span>
                <br></br>
                <br></br>
                <span>
                  When asked about Gateway.fm’s origin story, Igor recounts a
                  critical pain point that sparked the idea. “Back in 2020,
                  there was this alarming narrative—90% of Ethereum nodes were
                  running on AWS. It completely undermined the promise of
                  decentralization. Blockchain is supposed to reduce central
                  points of failure, but instead, we saw a system entirely
                  dependent on one cloud provider.”
                </span>
                <br></br>
                <br></br>
                <span>
                  Outages in these centralized systems left developers and
                  businesses scrambling. “We knew this couldn’t go on,” Igor
                  says. “Gateway.fm was born to decentralize infrastructure
                  while maintaining enterprise-level reliability.”
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span className="h2">
                  Early Challenges and Adapting to the Market
                </span>
                <br></br>
                <br></br>
                <span>
                  Gateway.fm’s initial focus was on providing robust RPC (Remote
                  Procedure Call) services. “We developed a technically superior
                  solution,” Igor explains, “but entering the market late made
                  it challenging to compete. Prices were being driven into
                  negative margins, which forced us to rethink.”
                </span>
                <br></br>
                <br></br>
                <span>
                  This shift led to Gateway.fm pivoting toward Layer 2 solutions
                  and staking services. “We realized we could apply our
                  knowledge to new areas, like optimizing Ethereum nodes and
                  deploying advanced automation for scaling blockchain
                  infrastructure,” Igor shares.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span className="h2">Lowering Barriers to Entry</span>
                <br></br>
                <br></br>
                <span>
                  Igor is passionate about making blockchain accessible to
                  everyone. “Running a blockchain node used to be
                  resource-intensive,” he explains. “Even for tech-savvy
                  individuals, it was daunting. We’ve worked to reduce these
                  barriers by innovating with lightweight nodes and stateless
                  Ethereum research.”
                </span>
                <br></br>
                <br></br>
                <span>
                  According to Igor, these efforts have made it easier for more
                  participants to engage with blockchain technology. “Adoption
                  is about accessibility,” he says. “If the bar is too high,
                  only a select few will benefit. We’re changing that.”
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span>Privacy, Compliance, and the Future</span>
                <br></br>
                <br></br>
                <span>
                  One of the biggest challenges in blockchain adoption is
                  addressing privacy concerns. Igor believes zero-knowledge (ZK)
                  cryptography is the answer. “ZK technology is like space
                  math,” he laughs. “It allows you to prove something—like
                  having enough funds for a transaction—without revealing any
                  sensitive details. It’s a game-changer for privacy and
                  scalability.”
                </span>
                <br></br>
                <br></br>
                <span>
                  On compliance, Igor points out how blockchain’s transparency
                  can work in its favor. “Auditors love blockchain because
                  everything is logged. It simplifies compliance processes. Of
                  course, the speculative token side of blockchain can muddy the
                  waters, but the technology itself is neutral and incredibly
                  powerful.”
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span className="h2">Looking Ahead: Our Mission</span>
                <br></br>
                <br></br>
                <span>
                  “Blockchain infrastructure needs to be reliable,
                  decentralized, and scalable,” Igor says. “At Gateway.fm, we’re
                  not just providing tools; we’re building the foundation for
                  the next wave of innovation.”
                </span>
                <br></br>
                <br></br>
                <span>
                  From enabling scalable Layer 2 solutions to ensuring secure
                  staking mechanisms, Gateway.fm is committed to making
                  blockchain technology robust and accessible. Igor emphasizes,
                  “This isn’t just about technology. It’s about empowering
                  people, businesses, and institutions to trust and embrace
                  blockchain as a cornerstone of their operations.”
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span>
                  Gateway.fm is leading the charge in redefining blockchain
                  infrastructure. “We’ve come a long way,” Igor concludes. “But
                  the best is yet to come. If you believe in the power of
                  decentralization and innovation, join us.”
                </span>
                <br className="from-rpc-to-real-world-text78"></br>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer rootClassName="footerroot-class-name62"></Footer>
    </div>
  )
}

export default FromRPCToRealWorld
