import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import Tag from './tag'
import './blog-post-featured.css'

const BlogPostFeatured = (props) => {
  return (
    <div className={`blog-post-featured-container ${props.rootClassName} `}>
      <div className="blog-post-featured-featuredpost">
        <img
          alt="gatewaywirexcasestudy17177"
          src={props.image}
          className="blog-post-featured-gatewaywirexcasestudy1"
        />
        <div className="blog-post-featured-featuredtitle">
          <div className="blog-post-featured-featuredtags">
            <Tag text="blog" rootClassName="tagroot-class-name"></Tag>
            <Tag text={props.tag} rootClassName="tagroot-class-name1"></Tag>
          </div>
          <span className="blog-post-featured-text1">
            {props.text ?? (
              <Fragment>
                <span className="blog-post-featured-text3">
                  <span>
                    A Partnership That Transformed The Crypto-Financial Industry
                  </span>
                </span>
              </Fragment>
            )}
          </span>
          <span className="blog-post-featured-text2">{props.date}</span>
        </div>
      </div>
    </div>
  )
}

BlogPostFeatured.defaultProps = {
  image: '/external/gatewaywirexcasestudy17177-zzw-1500w.png',
  date: '12 of June 2024',
  text: undefined,
  rootClassName: '',
  tag: 'partnership',
}

BlogPostFeatured.propTypes = {
  image: PropTypes.string,
  date: PropTypes.string,
  text: PropTypes.element,
  rootClassName: PropTypes.string,
  tag: PropTypes.string,
}

export default BlogPostFeatured
