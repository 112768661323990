import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navigation from '../../components/navigation'
import Tag from '../../components/tag'
import Footer from '../../components/footer'
import './blog-cdk-erigon.css'

const BlogCDKErigon = (props) => {
  return (
    <div className="blog-cdk-erigon-container1">
      <Helmet>
        <title>CDK Erigon</title>
        <meta
          name="description"
          content="CDK Erigon is designed to fully leverage the architecture from Erigon, enabling developers to build and deploy blockchain applications with greater efficiency."
        />
        <meta property="og:title" content="CDK Erigon" />
        <meta
          property="og:description"
          content="CDK Erigon is designed to fully leverage the architecture from Erigon, enabling developers to build and deploy blockchain applications with greater efficiency."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/d4fcd6b2-6443-488c-bc49-d486479662ff?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name54"></Navigation>
      <div className="blog-cdk-erigon-container2">
        <div className="blog-cdk-erigon-post">
          <img
            alt="CDK Erigon by Gateway.fm."
            src="/external/blog-banners/cdk-erigon-gateway-1200w.png"
            className="blog-cdk-erigon-image"
          />
          <div className="blog-cdk-erigon-text10">
            <div className="blog-cdk-erigon-container3">
              <div className="blog-cdk-erigon-tags">
                <Tag text="cdk erigon"></Tag>
                <Tag text="web3"></Tag>
              </div>
              <span className="blog-cdk-erigon-text11">
                CDK Erigon | Pushing the boundaries of blockchain infrastructure
              </span>
              <span className="blog-cdk-erigon-text12">26 of August 2024</span>
              <span className="blog-cdk-erigon-text13">
                <span>
                  In the rapidly evolving Web3 space performance, scalability
                  and efficiency are paramount. Erigon is a next-gen Ethereum
                  client that addresses these needs by pushing the boundaries of
                  blockchain infrastructure. Alongside Erigon is CDK Erigon, a
                  specialized toolkit developed by our team at Gateway.fm.
                </span>
                <br></br>
                <br></br>
                <span>
                  This toolkit is designed to fully leverage the unique
                  architecture from Erigon, enabling developers to build and
                  deploy blockchain applications with greater efficiency. Erigon
                  represents a significant leap forward and offers solutions to
                  some of the most pressing challenges faced by the Web3
                  community.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span className="h2">The Transaction Flow</span>
                <br></br>
                <br></br>
                <span>
                  At the heart of any blockchain system is the transaction flow.
                  In Erigon this process is streamlined and optimized for
                  efficiency. Transactions begin at a node and are promptly
                  forwarded to a sequencer. The sequencer plays a crucial role,
                  executing these transactions and updating the state — this new
                  state is what is called the ‘trusted state’.
                </span>
                <br></br>
                <br></br>
                <span>
                  The trusted state is then handed off to a sequence sender,
                  which bundles these transactions into batches. Depending on
                  the system design, these batches are either sent to a L1 chain
                  or a data availability committee (DAC). In rollups for
                  instance, batches are sent directly to L1 as sequence
                  transactions, leading to the creation of a ‘virtual state’
                  (intermediate state) once finalized. This state undergoes
                  rigorous verification, eventually becoming the ‘verified
                  state’ on L1.
                </span>
                <br></br>
                <br></br>
                <span>
                  This methodical approach to transaction processing ensures
                  that every state transition is carefully managed, with soft
                  finality achieved when transactions enter the trusted state
                  and hard finality upon verification. The inclusion of a bridge
                  between L1 and L2 further improves security, managing deposits
                  and withdrawals with precision to ensure consistency across
                  chain layers.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span className="h2">Architectural Innovations</span>
                <br></br>
                <br></br>
                <span>
                  Erigon distinguishes itself from legacy Ethereum nodes through
                  its innovative architecture. One of the most significant
                  departures is its use of data streaming rather than
                  traditional database sharing. This shift not only boosts
                  performance but also simplifies the process of state
                  management, making Erigon an attractive choice for developers
                  seeking efficiency.
                </span>
                <br></br>
                <br></br>
                <span>
                  Erigon data storage strategy is equally revolutionary. Unlike
                  the traditional Merkle tree structure, Erigon employs flat
                  storage, organizing data in a key-value format. This approach
                  minimizes disk space usage and accelerates data access, making
                  it both more efficient and easier to manage. The flat state
                  database is complemented by a modified Ethereum Virtual
                  Machine (EVM), further enhancing the system&apos;s ability to
                  separate verification properties from data storage and access.
                </span>
                <br></br>
                <br></br>
                <span>
                  These architectural choices make Erigon a powerful tool for
                  developers who require a robust, scalable and efficient
                  platform to build on.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span className="h2">State Management: Plain vs. Hash</span>
                <br></br>
                <br></br>
                <span>
                  In Erigon, state management is handled through two distinct
                  formats: plain state and hash state. The plain state operates
                  as a straightforward key-value store, allowing for quick and
                  efficient storage and retrieval of data. On the other hand,
                  the hash state serves as a hashed version of the plain state,
                  providing compatibility and integrity checks. Despite
                  maintaining these dual formats, Erigon has an efficient
                  storage model that ensures that the duplication does not lead
                  to significant performance overhead.
                </span>
                <br></br>
                <br></br>
                <span>
                  This dual-state approach is bolstered by the ability of Erigon
                  to store the state compactly, thanks to its flat storage
                  design. This contrasts with tree-based structures, which,
                  while useful, can be more complex and less efficient in terms
                  of space and speed.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span className="h2">The Power of Stage Sync</span>
                <br></br>
                <br></br>
                <span>
                  Erigon stage-based synchronization process is another standout
                  feature. This modular approach divides the synchronization
                  process into multiple stages, each responsible for a specific
                  task, from reading data from L1 to publishing it to the data
                  stream. This division not only enhances the system&apos;s
                  efficiency but also allows for independent updates and
                  optimizations of each stage.
                </span>
                <br></br>
                <br></br>
                <span>
                  For example, a sequencer might skip certain stages related to
                  transaction pools, focusing instead on data stream processing.
                  This flexibility makes Erigon adaptable to various use cases,
                  whether it&apos;s for high-frequency trading, where speed is
                  critical, or for decentralized applications that require
                  robust data management.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span className="h2">
                  Pioneering Data Streaming in Blockchain
                </span>
                <br></br>
                <br></br>
                <span>
                  One of the most intriguing features of Erigon is its use of a
                  data stream for synchronization, centralizing data flow
                  through a single sequence. This method is highly efficient,
                  particularly in environments where speed is of the essence,
                  such as high-frequency trading.
                </span>
                <br></br>
                <br></br>
                <span>
                  However, recognizing the potential for bottlenecks in this
                  centralized approach, Erigon is exploring a return to a more
                  decentralized peer-to-peer (P2P) system. This future direction
                  promises to enhance decentralization and performance,
                  addressing some of the challenges inherent in Ethereum&apos;s
                  current P2P systems.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span className="h2">State Finalization and Recovery</span>
                <br></br>
                <br></br>
                <span>
                  Erigon synchronization process is designed not just for
                  routine operations but also for recovery scenarios. Should all
                  instances of Erigon be lost due to a network bug or data
                  corruption, the system can initiate recovery from L1 or a data
                  availability committee, ensuring that the blockchain’s state
                  can be rebuilt and integrity restored.
                </span>
                <br></br>
                <br></br>
                <span>
                  Moreover, Erigon flexibility in handling gas limits and block
                  sizes—paired with its stateless executor — ensures that the
                  system remains robust and resilient under various conditions.
                  Virtual counters help manage resource limits, preventing the
                  creation of unprovable or excessively large blocks, while
                  parallel execution requests enhance overall efficiency.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span className="h2">The Team Behind Erigon</span>
                <br></br>
                <br></br>
                <span>
                  Gateway.fm is the team that developed Erigon. Our CTO,
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <a
                  href="https://x.com/mandrigin"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="blog-cdk-erigon-link"
                >
                  Igor Mandrigin
                </a>
                <span>
                  , one of the co-founders of Erigon, has guided our efforts to
                  push the boundaries of what’s possible in blockchain
                  infrastructure.
                </span>
                <br></br>
                <br></br>
                <span>
                  Our work doesn’t just stop at innovation; it extends to
                  practical implementation. CDK Erigon, built by our team, is
                  now the default RPC (Remote Procedure Call) for Polygon zkEVM
                  (Cardano Mainnet). This achievement underscores the
                  robustness, reliability and performance of our technology in
                  real-world, high-stakes environments.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span className="h2">The Road Ahead</span>
                <br></br>
                <br></br>
                <span>
                  As Erigon continues to evolve, its blend of innovative
                  architectural choices, efficient data management and
                  forward-looking synchronization processes positions it as a
                  leading solution in the blockchain space. For developers and
                  businesses operating in the Web3 ecosystem, Erigon offers a
                  compelling toolkit to build scalable, secure and
                  high-performance blockchain applications.
                </span>
                <br></br>
                <br></br>
                <span>
                  We are committed to R&amp;D focusing on bleeding edge
                  blockchain technology — as the blockchain industry continues
                  to grow and mature, Erigon stands out as a beacon of
                  innovation.
                </span>
                <br className="blog-cdk-erigon-text105"></br>
                <br className="blog-cdk-erigon-text106"></br>
                <Link to="/book-a-call" className="blog-cdk-erigon-navlink">
                  Book a call and hear what we can do for your Web3 project!
                </Link>
                <br className="blog-cdk-erigon-text107"></br>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer rootClassName="footerroot-class-name53"></Footer>
    </div>
  )
}

export default BlogCDKErigon
