import React from 'react'

import { Helmet } from 'react-helmet'

import Navigation from '../../components/navigation'
import Tag from '../../components/tag'
import Footer from '../../components/footer'
import './blog-gatewayfm-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar.css'

const BlogGatewayfmAnnouncesRoleInBuildingPublicSorobanRPCInfrastructureForStellar =
  (props) => {
    return (
      <div className="blog-gatewayfm-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar-container1">
        <Helmet>
          <title>
            Gateway.fm | Building Public Soroban RPC Infrastructure for Stellar
          </title>
          <meta
            name="description"
            content="Explore how Gateway.fm is developing the public Soroban RPC infrastructure for Stellar blockchain, enhancing accessibility and functionality for developers and users alike."
          />
          <meta
            property="og:title"
            content="Gateway.fm | Building Public Soroban RPC Infrastructure for Stellar"
          />
          <meta
            property="og:description"
            content="Explore how Gateway.fm is developing the public Soroban RPC infrastructure for Stellar blockchain, enhancing accessibility and functionality for developers and users alike."
          />
          <meta
            property="og:image"
            content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/343f88fc-5e77-45a7-8c77-bb5e839fb1de?org_if_sml=1&amp;q=80&amp;force_format=original"
          />
        </Helmet>
        <Navigation rootClassName="navigationroot-class-name26"></Navigation>
        <div className="blog-gatewayfm-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar-container2">
          <div className="blog-gatewayfm-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar-post">
            <img
              alt="Gateway.fm and Stellar."
              src="/external/blog-banners/gateway-stellar-partnership-1200w.webp"
              className="blog-gatewayfm-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar-image"
            />
            <div className="blog-gatewayfm-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar-text10">
              <div className="blog-gatewayfm-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar-container3">
                <div className="blog-gatewayfm-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar-tags">
                  <Tag text="stellar"></Tag>
                  <Tag text="rpc infrastructure"></Tag>
                </div>
                <span className="blog-gatewayfm-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar-text11">
                  Gateway.fm Announces Role in Building Public Soroban RPC
                  Infrastructure for Stellar
                </span>
                <span className="blog-gatewayfm-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar-text12">
                  7 of May 2024
                </span>
                <span className="blog-gatewayfm-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar-text13">
                  <span>Cuautemoc Weber, Co-Founder and CEO of Gateway.fm</span>
                  <br></br>
                  <br></br>
                  <span>
                    Recently, the Stellar Development Foundation (SDF) made
                    international headlines, announcing the rollout of Soroban,
                    the Stellar network’s highly anticipated smart contracts
                    platform. Soroban enables developers to create, deploy, and
                    engage with decentralized applications (dApps) on its
                    blockchain – ushering in a new era of Web3 innovation and
                    adoption. While this was a landmark moment for the Stellar
                    ecosystem, it was also a particularly sweet moment for us at
                    Gateway.fm, having had the privilege of working alongside
                    their team in making this vision a reality.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    We are proud to provide a public Soroban RPC infrastructure
                    on private data centers to facilitate network
                    decentralization. With our free public RPC infrastructure,
                    developers can effortlessly execute smart contracts and
                    build dApps on the Stellar/Soroban network. Gateway.fm is
                    well versed in delivering reliable node infrastructure,
                    best-in-class validators and robust dev tooling to help Web3
                    projects scale at pace. Over the course of Soroban’s
                    two-year testing phase – which Gateway was intimately
                    involved in – the platform attracted strong interest from
                    more than 190 projects across a broad spectrum of industries
                    from gaming, to lending, to insurance, so it was clear that
                    there was huge pent-up market demand for this type of
                    offering.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    The launch of Soroban is arguably the most significant
                    upgrade to the Stellar network to date, extending the
                    network’s capabilities to support DeFi and a host of other
                    applications. The rollout of Soroban also gives expression
                    to SDF’s unwavering commitment to accelerating the
                    proliferation of blockchain-based real-world applications
                    and democratizing access to financial services – two
                    important missions that Gateway.fm is firmly aligned with.
                    By streamlining transactions and reducing computing power
                    requirements, Soroban minimizes smart contract fees while
                    broadening accessibility and improving usability for
                    developers.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    We met the SDF team just over one year ago, at ETHDenver
                    2023. During our initial discussions, the idea of building
                    public RPC infrastructure for Soroban on private data
                    centers resonated with their team, aligning with their
                    values and overarching strategy. We were impressed by their
                    team’s extensive technical knowledge, and there were many
                    clear synergies to connect developers from the Stellar
                    ecosystem and help each other grow.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    Together, we want to broaden the accessibility of Web3
                    infrastructure and champion industry decentralization, while
                    our carbon-neutral data centers align with the Foundation’s
                    dedication to sustainability initiatives. By spreading the
                    RPC infrastructure across multiple independent data centers,
                    the network becomes less reliant on any one data center,
                    reducing the risk of a single point of failure. Reliable RPC
                    infrastructure also ensures network stability while
                    facilitating faster transaction processing times for
                    developers, driving adoption of the Stellar network.
                    Moreover, with reliable RPC infrastructure, developers can
                    focus on building their applications without worrying about
                    the underlying infrastructure.
                  </span>
                  <br></br>
                  <br></br>
                  <span>What&apos;s next for Gateway.fm</span>
                  <br></br>
                  <span>
                    As the demand for Web3 services continues to grow, the
                    ability to scale infrastructure quickly and efficiently
                    becomes critical to meet user needs and mitigate the risk of
                    service disruptions. Moving forward, we will continue
                    providing reliable and uninterrupted RPC node services,
                    which is crucial in terms of onboarding and retaining
                    customers. Additionally, we will be integrating new features
                    in line with evolving industry and user requirements.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    Right now, our signature product, Presto, is greatly
                    simplifying the establishment and upkeep of blockchain
                    networks, enabling more businesses to tap into the potential
                    of blockchain, driving quicker adoption and implementation.
                    Just like how today, anyone can build a website without
                    fully understanding the underlying infrastructure, we are
                    making blockchain technology easy to use and build on top
                    of, without requiring extensive knowledge in cryptography or
                    blockchain protocols. This means companies and developers
                    can bypass the steep learning curve associated with
                    cryptographic algorithms, consensus mechanisms and smart
                    contracts, while unlocking the benefits of enhanced
                    scalability, reduced transaction costs, and improved
                    privacy.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    Our team has an excellent track record in successfully
                    managing complex technical integrations and enabling
                    projects to deploy a state-of-the-art full E2E zk-validium
                    or zk-rollup – all within a couple of clicks and minutes.
                    Zk-implementations offer enhanced scalability, reduced
                    transaction costs, and improved privacy, making them
                    valuable for Web3 and decentralized projects.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    Interested parties can start building with Presto today
                    using our developer docs. If you have an idea you&apos;d
                    like to discuss, feel free to reach out to temoc@gateway.fm,
                    and let&apos;s work together to build incredible zkRollups
                    using cutting-edge zkEVM technology!
                  </span>
                  <br></br>
                </span>
              </div>
            </div>
          </div>
        </div>
        <Footer rootClassName="footerroot-class-name25"></Footer>
      </div>
    )
  }

export default BlogGatewayfmAnnouncesRoleInBuildingPublicSorobanRPCInfrastructureForStellar
