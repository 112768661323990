import React from 'react'

import { Helmet } from 'react-helmet'

import Navigation from '../../components/navigation'
import Tag from '../../components/tag'
import Footer from '../../components/footer'
import './blog-staking-bitcoin-with-babylon.css'

const BlogStakingBitcoinWithBabylon = (props) => {
  return (
    <div className="blog-staking-bitcoin-with-babylon-container1">
      <Helmet>
        <title>Gateway.fm | Staking Bitcoin With Babylon</title>
        <meta
          name="description"
          content="Babylon introduces a novel approach to enhancing Proof-of-Stake (PoS) blockchains by leveraging the security Bitcoin offers."
        />
        <meta
          property="og:title"
          content="Gateway.fm | Announcing Our Node Sales"
        />
        <meta
          property="og:description"
          content="Babylon introduces a novel approach to enhancing Proof-of-Stake (PoS) blockchains by leveraging the security Bitcoin offers."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/b7ffe35d-0047-4d18-83f5-41371a802881?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name50"></Navigation>
      <div className="blog-staking-bitcoin-with-babylon-container2">
        <div className="blog-staking-bitcoin-with-babylon-post">
          <img
            alt="Babylon BTC staking."
            src="/external/blog-banners/stakeway-babylon-1200w.jpg"
            className="blog-staking-bitcoin-with-babylon-gatewaywirexcasestudy1"
          />
          <div className="blog-staking-bitcoin-with-babylon-text10">
            <div className="blog-staking-bitcoin-with-babylon-container3">
              <div className="blog-staking-bitcoin-with-babylon-tags">
                <Tag text="staking"></Tag>
                <Tag text="bitcoin"></Tag>
              </div>
              <span className="blog-staking-bitcoin-with-babylon-text11">
                Staking Bitcoin With Babylon
              </span>
              <span className="blog-staking-bitcoin-with-babylon-text12">
                8 of August 2024
              </span>
              <span className="blog-staking-bitcoin-with-babylon-text13">
                <a
                  href="https://babylonlabs.io/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="blog-staking-bitcoin-with-babylon-link"
                >
                  Babylon
                </a>
                <span>
                  {' '}
                  introduces a novel approach to enhancing Proof-of-Stake (PoS)
                  blockchains by leveraging the security Bitcoin offers. This
                  innovative solution allows Bitcoin holders to earn rewards
                  from their idle assets without the need to transfer them to a
                  different blockchain. By locking their Bitcoins, users gain
                  the ability to validate PoS chains and earn yield. Babylon
                  offers quick unbonding and native Bitcoin staking, ensuring
                  that Bitcoin stakers enjoy optimal liquidity and returns.
                  Importantly, it is self-custodial.
                </span>
                <br></br>
                <br></br>
                <span>
                  Babylon Series A funding round attracted notable investors
                  such as Paradigm, Hack VC and Polychain Capital, with
                  additional support from Polygon Ventures, Framework Ventures
                  and OKX Ventures. These investments are being used to develop
                  a Bitcoin staking protocol that bridges the gap between the
                  DeFi ecosystem and the Bitcoin blockchain.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span className="h2">
                  Innovating the Financial Utility of Bitcoin
                </span>
                <br></br>
                <br></br>
                <span>
                  Bitcoin stands as a cornerstone of blockchain technology,
                  renowned for its robust security provided by its Proof-of-Work
                  (PoW) system. As the pioneering digital currency, Bitcoin
                  emerged in 2009 setting the stage for the future of
                  decentralized financial systems. With the largest market
                  capitalization and unparalleled secure track record Bitcoin
                  remains the number one asset in crypto. In today’s blockchain
                  space, Web3, this offers opportunities and Babylon innovates
                  the financial utility of Bitcoin.
                </span>
                <br></br>
                <br></br>
              </span>
              <span className="blog-staking-bitcoin-with-babylon-text27">
                <span className="h2">Staking Idle Bitcoin</span>
                <br className="blog-staking-bitcoin-with-babylon-text29"></br>
                <br className="blog-staking-bitcoin-with-babylon-text30"></br>
                <span className="text">
                  Approximately 70% of Bitcoin supply has been inactive for the
                  past year, highlighting a significant opportunity. While PoS
                  chains frequently generate yield through active participation,
                  Bitcoin inactivity presents a challenge for integration into
                  these systems. Existing solutions often involve bridging or
                  custody which come with certain risks.
                </span>
                <br className="text"></br>
                <br className="text"></br>
                <span className="text">
                  In PoS systems, validators commit their cryptocurrency
                  holdings to secure the network, whereas the Bitcoin PoW
                  mechanism secures the network through computational efforts.
                  This distinction allows Bitcoin to be used more flexibly,
                  freeing up its assets for other purposes while PoS chains rely
                  on financial stakes for security.
                </span>
                <br className="text"></br>
                <br className="text"></br>
                <span className="text">
                  The DeFi space, predominantly driven by PoS systems, could
                  benefit from Bitcoin&apos;s security features. Babylon
                  combines Bitcoin’s PoW security with the energy-efficient PoS
                  consensus mechanism, offering a unique solution that leverages
                  Bitcoin’s strength to address vulnerabilities in PoS networks.
                  This integration enhances the security and functionality of
                  PoS chains, whether for new developments or existing systems.
                </span>
                <br className="blog-staking-bitcoin-with-babylon-text38"></br>
                <br className="blog-staking-bitcoin-with-babylon-text39"></br>
                <br className="blog-staking-bitcoin-with-babylon-text40"></br>
                <span className="h2">Origin of Babylon</span>
                <br className="blog-staking-bitcoin-with-babylon-text42"></br>
                <br className="blog-staking-bitcoin-with-babylon-text43"></br>
                <span className="blog-staking-bitcoin-with-babylon-text44">
                  Developed under the guidance of Professor David Tse and
                  supported by a team of blockchain experts including Xinshu
                  Dong and Stanford Tse Lab — Babylon addresses critical
                  cross-chain blockchain challenges. The research paper
                  co-authored by Tse highlighted PoS vulnerabilities and
                  inspired the Babylon protocol to improve PoS security using
                  Bitcoin.
                </span>
                <br className="blog-staking-bitcoin-with-babylon-text45"></br>
                <br className="blog-staking-bitcoin-with-babylon-text46"></br>
                <br className="blog-staking-bitcoin-with-babylon-text47"></br>
                <span className="h2">
                  Babylon Bitcoin Staking Protocol - EOTS
                </span>
                <br className="blog-staking-bitcoin-with-babylon-text49"></br>
                <br className="blog-staking-bitcoin-with-babylon-text50"></br>
                <span className="blog-staking-bitcoin-with-babylon-text51">
                  Babylon utilizes Extractable One-Time Signature (EOTS)
                  technology, allowing Bitcoin holders to participate in PoS
                  security while keeping their assets secure. EOTS is derived
                  from the Schnorr signature algorithm, enabling Bitcoin staking
                  on the Bitcoin network without third-party interference.
                  Babylon advanced time-stamping protocol ensures
                  synchronization between Bitcoin and PoS chains, facilitating
                  rapid unbonding.
                </span>
                <br className="blog-staking-bitcoin-with-babylon-text52"></br>
                <br className="blog-staking-bitcoin-with-babylon-text53"></br>
                <span className="blog-staking-bitcoin-with-babylon-text54">
                  Babylon’s modular design supports scalable staking across
                  various PoS chains. Bitcoin holders can choose which PoS
                  chains to support and earn rewards accordingly. The protocol
                  allows developers to configure stake amounts and rewards,
                  optimizing the crypto economy.
                </span>
                <br className="blog-staking-bitcoin-with-babylon-text55"></br>
                <br className="blog-staking-bitcoin-with-babylon-text56"></br>
                <br className="blog-staking-bitcoin-with-babylon-text57"></br>
                <span className="h2">
                  Babylon structure includes a 3-Layer Architecture
                </span>
                <br className="blog-staking-bitcoin-with-babylon-text59"></br>
                <br className="blog-staking-bitcoin-with-babylon-text60"></br>
                <span className="blog-staking-bitcoin-with-babylon-text61">
                  - Bitcoin Layer: Utilizes Bitcoin security for transaction
                  protection.
                </span>
                <br className="blog-staking-bitcoin-with-babylon-text62"></br>
                <br className="blog-staking-bitcoin-with-babylon-text63"></br>
                <span className="blog-staking-bitcoin-with-babylon-text64">
                  - Babylon PoS Chain: Connects Bitcoin with PoS chains, running
                  smart contracts and handling transactions.
                </span>
                <br className="blog-staking-bitcoin-with-babylon-text65"></br>
                <br className="blog-staking-bitcoin-with-babylon-text66"></br>
                <span className="blog-staking-bitcoin-with-babylon-text67">
                  - PoS Layers: Comprises various PoS chains, chosen for their
                  unique strengths to provide scalability and flexibility.
                </span>
                <br className="blog-staking-bitcoin-with-babylon-text68"></br>
                <br className="blog-staking-bitcoin-with-babylon-text69"></br>
                <br className="blog-staking-bitcoin-with-babylon-text70"></br>
                <span className="h2">Unlimited PoS Chain Validation</span>
                <br className="blog-staking-bitcoin-with-babylon-text72"></br>
                <br className="blog-staking-bitcoin-with-babylon-text73"></br>
                <span className="blog-staking-bitcoin-with-babylon-text74">
                  Babylon protocol allows validators to participate in an
                  extensive range of PoS chains, earning commissions through
                  validation. Bitcoin must be lockable, delegatable, slashable
                  and have an unbonding period to qualify as a staking asset.
                </span>
                <br className="blog-staking-bitcoin-with-babylon-text75"></br>
                <br className="blog-staking-bitcoin-with-babylon-text76"></br>
                <br className="blog-staking-bitcoin-with-babylon-text77"></br>
                <span className="h2">Safe Delegation and Slashing</span>
                <br className="blog-staking-bitcoin-with-babylon-text79"></br>
                <br className="blog-staking-bitcoin-with-babylon-text80"></br>
                <span className="blog-staking-bitcoin-with-babylon-text81">
                  Babylon ensures secure delegation by using EOTS public keys
                  under slashing conditions. Users can prevent collusion between
                  validators and covenant committees by incorporating their
                  public key into slashing conditions. The protocol uses three
                  sets of signatures to safeguard against theft and ensures that
                  misbehavior results in slashing.
                </span>
                <br className="blog-staking-bitcoin-with-babylon-text82"></br>
                <br className="blog-staking-bitcoin-with-babylon-text83"></br>
                <br className="blog-staking-bitcoin-with-babylon-text84"></br>
                <span className="h2">Atomic and Partial Slashing</span>
                <br className="blog-staking-bitcoin-with-babylon-text86"></br>
                <br className="blog-staking-bitcoin-with-babylon-text87"></br>
                <span className="blog-staking-bitcoin-with-babylon-text88">
                  Babylon’s protocol supports atomic slashing, where malicious
                  validators risk exposure of their EOTS secret key, affecting
                  all their delegated stakes. Partial slashing, supported by
                  many PoS systems, mitigates the impact of slashing by only
                  penalizing a portion of the stake, with delayed refunds to
                  prevent abuse.
                </span>
                <br className="blog-staking-bitcoin-with-babylon-text89"></br>
                <br className="blog-staking-bitcoin-with-babylon-text90"></br>
                <br className="blog-staking-bitcoin-with-babylon-text91"></br>
                <span className="h2">Restaking Capabilities</span>
                <br className="blog-staking-bitcoin-with-babylon-text93"></br>
                <br className="blog-staking-bitcoin-with-babylon-text94"></br>
                <span className="blog-staking-bitcoin-with-babylon-text95">
                  Babylon enables Bitcoin multi-staking, allowing users or
                  delegated finality providers to participate in multiple PoS
                  systems simultaneously. This flexibility ensures that
                  Babylon’s protocol can transform Bitcoin into a versatile
                  staking asset with lockable, slashable, delegatable, and
                  restakable properties.
                </span>
                <br className="blog-staking-bitcoin-with-babylon-text96"></br>
                <br className="blog-staking-bitcoin-with-babylon-text97"></br>
                <br className="blog-staking-bitcoin-with-babylon-text98"></br>
                <span className="h2">Stakeway by Gateway</span>
                <br className="blog-staking-bitcoin-with-babylon-text100"></br>
                <span className="blog-staking-bitcoin-with-babylon-text101">
                  We provide staking solutions over at
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="blog-staking-bitcoin-with-babylon-text102">
                  Stakeway.com
                </span>
                <span className="blog-staking-bitcoin-with-babylon-text103">
                  {' '}
                  — visit and
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="blog-staking-bitcoin-with-babylon-text104">
                  book a call
                </span>
                <span className="blog-staking-bitcoin-with-babylon-text105">
                  !
                </span>
                <br className="blog-staking-bitcoin-with-babylon-text106"></br>
                <br className="blog-staking-bitcoin-with-babylon-text107"></br>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer rootClassName="footerroot-class-name49"></Footer>
    </div>
  )
}

export default BlogStakingBitcoinWithBabylon
