import React from 'react'

import PropTypes from 'prop-types'

import './button-primary-subtitle.css'

const ButtonPrimarySubtitle = (props) => {
  return (
    <div
      id={props.divID}
      onclick={props.onclick}
      className={`button-primary-subtitle-container1 ${props.rootClassName} `}
    >
      <div
        id="button-primary-subtitle-container"
        className="button-primary-subtitle-container2"
      >
        <div className="button-primary-subtitle-texts">
          <span className="button-primary-subtitle-text1">{props.main}</span>
          <span className="button-primary-subtitle-text2">
            {props.supportive}
          </span>
        </div>
      </div>
    </div>
  )
}

ButtonPrimarySubtitle.defaultProps = {
  onclick: '',
  rootClassName: '',
  supportive: 'Initiate this blueprint',
  divID: 'button-id',
  main: 'Get started',
}

ButtonPrimarySubtitle.propTypes = {
  onclick: PropTypes.string,
  rootClassName: PropTypes.string,
  supportive: PropTypes.string,
  divID: PropTypes.string,
  main: PropTypes.string,
}

export default ButtonPrimarySubtitle
